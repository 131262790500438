<template>
  <div class="form-stepper form-stepper-campaign">
    <v-form ref="form" v-model="valid" class="form pa-6" @submit.prevent>
      <p>{{ $t("CAMPAIGN.INTRODUCTION_TITLE") }}</p>
      <div class="pa-0">
        <v-row>
          <v-col cols="8">
            <v-text-field
              dense
              v-model="title"
              autofocus
              :rules="[$rules.required, $rules.maxlength(255)]"
              :label="$t('INPUT.TITLE')"
              :placeholder="$t('INPUT.TITLE')"
              @keyup.enter="false"
              outlined
              required
            />
          </v-col>
        </v-row>
      </div>

      <div class="pa-0">
        <v-row>
          <v-col>
            <p class="mb-0">{{ $t("CAMPAIGN.MODES_TITLE") }}</p>
            <p class="grey--text">
              <small>{{ $t("CAMPAIGN.MODES_TEXT") }}</small>
            </p>
          </v-col>
          <explanation-dialog for="campaign" />
        </v-row>
        <v-row>
          <template v-for="(item, i) in $t('CAMPAIGN.CHANNEL_CATEGORY_ITEMS')">
            <v-col cols="4" md="4" lg="3" xl="2" :key="'mo' + i">
              <div
                :class="[
                  'selection-box rounded pa-4 height-100',
                  channel_categories.includes(item.category)
                    ? 'bordered-selected elevation-4'
                    : 'bordered',
                ]"
                @click="addChannelCategory(item.category)"
              >
                <h4 class="text-nowrap text-ellipsis ma-0">{{ item.title }}</h4>
                <div class="ma-0 grey--text">
                  <small>{{ item.description }}</small>
                </div>
                <template v-if="channel_categories.includes(item.category)">
                  <v-icon class="corner-icon elevation-2" color="success">mdi-check-circle</v-icon>
                </template>
              </div>
            </v-col>
          </template>
          <v-select
            class="none"
            v-model="channel_categories"
            item-value="category"
            item-text="category"
            :items="$t('CAMPAIGN.CHANNEL_CATEGORY_ITEMS')"
            :rules="[$rules.required]"
            multiple
          />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
             color="secondary black--text"
             rounded
             depressed
             :disabled="channel_categories.length === $t('CAMPAIGN.CHANNEL_CATEGORY_ITEMS').length"
             small
             @click="channel_categories = $t('CAMPAIGN.CHANNEL_CATEGORY_ITEMS').map(v => v.category)"
            >
              <v-icon class="mr-2" size="18">mdi-playlist-check</v-icon>
              <span>{{$t('CAMPAIGN.SELECT_ALL_CATEGORY')}}</span>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-select
          class="d-none"
          v-model="methods"
          :items="$t('PUBLICATION.METHOD_ITEMS')"
          multiple
          item-text="text"
          item-value="value"
        />
        <v-select
          class="d-none"
          v-model="types"
          :items="$t('PUBLICATION.TYPE_ITEMS')"
          multiple
          item-text="text"
          item-value="value"
        /> -->
      </div>

      <p class="grey--text mt-6" v-if="channel_categories">
        {{ $tc("CHANNEL.TITLE_COUNT", channelSubscriptionItems.length || 0) }}
      </p>
      <div v-if="channelSubscriptionItems.length > 0">
        <div class="d-flex flex-wrap">
          <template v-for="(item, i) in channelSubscriptionItems">
            <div
              :key="'ch' + i"
              class="thumb-channel"
            >
              <v-img width="80px" max-height="25px" contain :src="getImage(item)" />
              <div class="thumb-channel-category">{{item.channel.category}}</div>
            </div>
          </template>
        </div>
      </div>

    </v-form>

    <v-divider />
    <v-toolbar color="transparent" flat height="70px" class="form-footer">
      <v-spacer />
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t("COMMON.CONTINUE") }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<style>

.thumb-channel {
  position: relative;
  padding: 10px;
  border: solid 1px rgba(0,0,0,.3);
  margin: 0 5px 5px 0;
  border-radius: 3px;
}

.thumb-channel-category {
  position: absolute;
  right:0;
  bottom:0;
  text-transform: uppercase;
  padding: 0 5px;
  position: absolute;
  font-size: 10px;
}

</style>


<script>
import ExplanationDialog from "@/components/ExplanationDialog.vue";

export default {
  name: "FormCampaignStepperIntro",
  components: {
    ExplanationDialog,
  },
  data: (_this) => ({
    loading: false,
    category: 'credit',
    mode: undefined,
    valid: false,
    channelSubscriptionItems: [],
    //
    title: null,
    channel_categories: [],
    // methods: [],
    // types: [],
    define_ga4_params: false,
    ga4_property_id: null,
    ga4_conversion_event_name: null,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    // mode(n) {
    //   if (n) {
    //     this.methods = [...n.methods];
    //     this.types = [...n.types];
    //   } else {
    //     this.methods = [];
    //     this.types = [];
    //   }
    //   this.loadChannelSubscriptionItems();
    // },
    channel_categories () {
      this.loadChannelSubscriptionItems()
    },
    values(n) {
      this.init();
    },
  },
  mounted() {
    this.mode = this.$i18n.t("CAMPAIGN.MODE_ITEMS")[0]
    this.init()
  },
  methods: {

    addChannelCategory (category) {
      const index = this.channel_categories.indexOf(category)
      if (index === -1) this.channel_categories.push(category)
      else this.channel_categories.splice(index, 1)
    },

    getImage(item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    loadChannelSubscriptionItems() {
      this.loading = true;
      this.$services.api_programmatic.channel_subscription
        .search({
          where: {
            status: [1],
            channel: {
              required: true,
              category: this.channel_categories,
            },
          },
        })
        .then((response) => {
          if (response.data) {
            this.channelSubscriptionItems = response.data;
          } else {
            this.channelSubscriptionItems = []
          }
        })
        .finally(() => {
          this.loading = false
        });
    },

    init() {
      Object.keys(this.values).forEach((key) => {
        if (
          typeof this.values[key] !== "undefined" &&
          typeof this[key] !== "undefined"
        ) {
          this[key] = this.values[key]
        }
      });
    },
    validate() {
      if (!this.$refs.form.validate()) {
        this.$emit("validation", false)
        return;
      }

      const data = {};
      data.title = this.title
      data.channel_categories = this.channel_categories
      // data.methods = this.methods
      // data.types = this.types
      // data.mode = this.mode

      this.$emit("childData", data)
      this.$emit("validation", true)
      this.$emit("next")
    },
  },
};
</script>

<style scoped>
</style>
